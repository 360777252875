import React from "react"
import { Navigate } from "react-router-dom"

// Login
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Workflow
import Workflow from "../pages/Workflow"
import InboundPaymentProcessing from "pages/Workflow/InboundPaymentProcessing"

// Action
import Action from "pages/Actions"

// Tickets
import Ticket from "pages/Tickets"

// Report
// import ZeroOutSummaryReport from "pages/Reports/ZeroOutSummary/index"
// import InboundDaySummary from "pages/Reports/InboundDaySummary"
// import TimelineReport from "pages/Reports/Timeline"

const authProtectedRoutes = [
  // Default Path
  {
    path: "/",
    exact: true,
    component: <Navigate to="/workflow" />,
  },
  // Workflow Page
  { path: "/workflow", component: <Workflow /> },
  {
    path: "/inbound-payment-processing",
    component: <InboundPaymentProcessing data={{}} />,
  },
  // Action Page
  {
    path: "/action",
    component: <Action />,
  },
  // Tickets Page
  {
    path: "/tickets",
    component: <Ticket />,
  },
  // Report Page
  // {
  //   path: "/zero-out-summary-report",
  //   component: <ZeroOutSummaryReport />,
  // },
  // {
  //   path: "/inbound-day-summary",
  //   component: <InboundDaySummary/>,
  // },
  // {
  //   path: "/timeline",
  //   component: <TimelineReport />,
  // },
]

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
]

export { authProtectedRoutes, publicRoutes }

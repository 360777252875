import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isWorkflow, setIsWorkflow] = useState<boolean>(false);
  const [isActions, setIsActions] = useState<boolean>(false);
  const [isTickets, setIsTickets] = useState<boolean>(false);
  // const [isReports, setIsReports] = useState<boolean>(false);
  // Pages

  const [iscurrentState, setIscurrentState] = useState("");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems:any = ul?.querySelectorAll(".nav-icon.active");
      const activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        const id = item.getAttribute("subitems");
        if (document.getElementById(id)){
          document?.getElementById(id)?.classList?.remove("show")}
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Workflows"){
      setIsWorkflow(false)
    }
    if (iscurrentState !== "Actions"){
      setIsActions(false)
    }
    if (iscurrentState !== "Tickets"){
      setIsTickets(false)
    }
    // if (iscurrentState !== "Reports"){
    //   setIsReports(false)
    // }
  }, [history, iscurrentState, isWorkflow]);

  const menuItems: any = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "workflow",
      label: "Workflows",
      icon: "rmx ri-git-pull-request-line",
      link: "/workflow",
      stateVariables: isWorkflow,
      click: function(e: any) {
        e.preventDefault()
        setIsWorkflow(!isWorkflow)
        setIscurrentState("Workflows");
      },
    },
    {
      id: "action",
      label: "Actions",
      icon: "rmx ri-task-line",
      link: "/action",
      stateVariables: isActions,
      click: function(e: any) {
        e.preventDefault();
        setIsActions(!isActions)
        setIscurrentState("Actions");
      },
    },
    {
      id: "tickets",
      label: "Tickets",
      icon: "rmx ri-ticket-line",
      link: "/tickets",
      stateVariables: isTickets,
      click: function(e: any) {
        e.preventDefault();
        setIsTickets(!isTickets)
        setIscurrentState("Tickets");
      },
    },
    // {
    //   id: "reports",
    //   label: "Reports",
    //   icon: "rmx ri-ticket-line",
    //   link: "/#",
    //   click: function(e: any) {
    //     e.preventDefault();
    //     setIsReports(!isReports)
    //     setIscurrentState("Reports");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isReports,
    //   subItems: [
    //     {
    //       id: "zeroOutSummaryReport",
    //       label: "Zero Out Summary Report",
    //       link: "/zero-out-summary-report",
    //       parentId: "reports",
    //     }
    //   ].filter(Boolean),
    // },
  ].filter(Boolean);
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
